import { Controller } from "@hotwired/stimulus"

export default class extends Controller {  
    static targets = ["searchform", "searchfield", "searchresults"]

    update() {
        if(this.searchfieldTarget.value != '') {
            //console.log("fire")
            this.searchformTarget.requestSubmit()
        } else {
            this.clear()
        }
    }

    clear(event) {
        //console.log("clear")
        this.searchresultTarget.innerHTML = ""
        this.searchfieldTarget.value = ""
    }
    
}