import { Application } from "@hotwired/stimulus"
//import { autoload } from "@hotwired/stimulus/webpack-helpers"

//import Lightbox from 'stimulus-lightbox'
import Sortable from 'stimulus-sortable'
import Clipboard from 'stimulus-clipboard'
import Carousel from 'stimulus-carousel'

// Load the lgVideo plugin
//import lgVideo from 'lightgallery/plugins/video'

const application = Application.start()
//const controllers = require.context("./controllers", true, /\.js$/ )

//application.register('lightbox', Lightbox)
application.register('sortable', Sortable)
application.register('clipboard', Clipboard)
application.register('carousel', Carousel)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }



 