import { Controller } from "@hotwired/stimulus"

export default class extends Controller {  
  initialize() {
    this.sd = this.targets.find("start_date")
    this.ed = this.targets.find("end_date")
  }

  log() {
    //console.log(this.targets.find("name").value)
    console.log("hello")
  }

  checkstatus(event) {
    //const cb = document.querySelector('#alldayCheckbox')
    const st = this.targets.find("start_time")
    const et = this.targets.find("end_time")
    //console.log(event.checked);
    
    if (this.targets.find("allday").checked) {
      st.disabled = true;
      et.disabled = true;
    } else {
      st.disabled = false;
      et.disabled = false;
    }
  }

  aligenddate() {
    const sddate = Date.parse(this.sd.value)
    const eddate = Date.parse(this.ed.value)
    
    if (sddate > eddate) {
      //console.log("start date past end date")
      this.ed.value = this.sd.value
    }
  }

  aligstartdate() {
    const sddate = Date.parse(this.sd.value)
    const eddate = Date.parse(this.ed.value)
    if (eddate < sddate) {
      //console.log("start date past end date")
      this.sd.value = this.ed.value
    }
  }

}
