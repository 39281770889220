import { Controller } from "@hotwired/stimulus"

export default class extends Controller {  
  static targets = ["formfields", "datestring", "nextbutton"]
  
  initialize() {
    this.dates = this.datestringTarget.value.split(",")
  }

  connect() {
    this.nextbutton()
  }

  toggle(event) {
    this.active = this.targets.find(event.params.date)
    this.active.classList.toggle('bi-plus-circle')
    this.active.classList.toggle('bi-check-circle-fill')
    this.active.classList.toggle('text-success')
    
    var index = this.dates.indexOf(event.params.date);
    if (index == -1) {
        this.dates.push(event.params.date)
    } else {
        this.dates.splice(index, 1);
    }
    this.removeempty()
    this.datestringTarget.value = this.dates
    this.nextbutton()
  }

  removeempty() {
    var index = this.dates.indexOf("");
    if (index != -1) {
      this.dates.splice(index, 1);
    }
  }

  nextbutton() {
    if (this.datestringTarget.value == "") {
        this.nextbuttonTarget.disabled = true
    } else {
        this.nextbuttonTarget.disabled = false
    }
  }

}