import { Controller } from "@hotwired/stimulus"

export default class extends Controller {  
    static targets = ["chatmessagebar", "ch", "chatinput", "form", "chscroll"]

    connect() {
        var barHeight = this.chatmessagebarTarget.clientHeight;
        this.chTarget.style['padding-bottom'] = barHeight + "px";
        var messagesHeight = this.chTarget.style['padding-bottom'];
        
        this.scrollDown();

        const targetNode = document.getElementById('messages');

        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true, subtree: true };

        // Callback function to execute when mutations are observed
        const callback = (mutationList, observer) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    console.log('A child node has been added or removed.');
                    this.scrollDown();
                } else if (mutation.type === 'attributes') {
                    console.log(`The ${mutation.attributeName} attribute was modified.`);
                }
            }
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);

    }

    scrollDown() {
        //console.log('fire');
        var boxheight = this.chscrollTarget.scrollHeight;
        this.chscrollTarget.scrollTop = boxheight;
        //console.log(this.chscrollTarget.scrollTop);
    }

    clear() {
        this.chatinputTarget.value = "";
    }
}
