import { Controller } from "@hotwired/stimulus"

export default class extends Controller {  
  static targets = ["newinvoicebutton", "loader"]
  

  initialize() {
    //this.loaderTarget.classList.toggle('d-none')
  }

  connect() {    
  }

  startLoad() {
    console.log("startLoad")
    this.loaderTarget.classList.toggle('d-none')
    this.newinvoicebuttonTarget.classList.toggle('d-none')
  }

}