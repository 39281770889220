import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  log() {
    console.log(this.targets.find("name").value)
  }
  //connect() {
  //  this.element.textContent = "Hello World!"
  //}
}
